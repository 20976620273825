import { FastFieldProps } from "formik";
import React from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { trueOrUndef } from "../../utils/dom-attribute-helpers";
import { dropDownArrowCss } from "../combobox/dropdown-arrow-css";
import { ErrorMessage } from "../input/Input";
import {
  RelatedContentSelector,
  useRelatedContentContext
} from "./CustomMarkdownComponents";
import { FormFieldElement, FormFieldInputWrapper } from "./FormFieldElement";
import { FormFieldLabel } from "./FormFieldLabel";

export const Select = styled.select`
  appearance: none;
  border: ${pxToRem(1)} solid var(--input-border-color);
  border-radius: 0.5rem;
  color: var(--input-text-color);
  padding: 0.8125rem 1.25rem;
  padding-right: ${pxToRem(20 + 18)};
  width: 100%;
  font-family: inherit;
  font-size: 1rem;

  background-color: #fff;
  ${dropDownArrowCss}

  &[aria-invalid] {
    border-color: var(--input-error-border-color);
  }
`;

type Props = {
  id: string;
  label: string;
  name: string;
} & React.ComponentPropsWithoutRef<typeof Select> &
  Omit<FastFieldProps, "form">;
const SelectField: React.FC<Props> = ({
  name,
  label,
  field,
  meta,
  ...props
}) => {
  const showError = meta.touched && !!meta.error;
  const { isSelected, related } = useRelatedContentContext(
    name as RelatedContentSelector
  );

  return (
    <FormFieldElement data-related-selected={isSelected}>
      <FormFieldLabel htmlFor={props.id}>{label}</FormFieldLabel>
      <FormFieldInputWrapper>
        <Select
          name={name}
          data-error={meta.error ? "" : undefined}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
          aria-invalid={trueOrUndef(showError)}
          aria-errormessage={showError ? meta.error : undefined}
        />
        {related}
      </FormFieldInputWrapper>
      {showError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FormFieldElement>
  );
};

export default SelectField;
