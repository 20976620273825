import { pxToRem } from "@input-output-hk/px-to-rem";
import { search } from "fast-fuzzy";
import { NextRouter, useRouter } from "next/router";
import React, { useState } from "react";
import styled from "styled-components";
import { toArray, toStrippedArray } from "../../api-utils/item-filters";
import { FacetMap } from "../../data/db/types";
import colors from "../../tokens/colors";
import Combobox, { ComboxOptions } from "../combobox/Combobox";
import { Select } from "../contribute/SelectField";
import { ArticleSort } from "../search/shared";
import Tag from "../tag/Tag";

export const activeFiltersCount = (query: NextRouter["query"]) => {
  let count = 0;
  count += toArray(query.tags).length;
  if (query.sort) {
    count++;
  }
  return count;
};

export const stripFilters = (
  query: NextRouter["query"],
  ...toStrip: string[]
) => {
  const next = { ...query };
  toStrip.forEach((key) => {
    delete next[key];
  });
  return next;
};

export const Tags = styled.div`
  min-height: ${pxToRem(88)};
  margin-bottom: var(--spacing-xsmall);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: var(--spacing-xsmall);
  margin: calc(var(--spacing-xsmall) * -1);
  > * {
    margin: var(--spacing-xsmall);
  }
`;

const MediaFiltersElement = styled.div`
  display: flex;
  justify-self: end;
  & > * + * {
    margin-left: var(--spacing-default);
  }
`;
const MediaFilter = styled.div`
  max-width: ${pxToRem(180)};
`;

export type SortOption = { value: ArticleSort; label: string };

export const SORT: SortOption[] = [
  { value: "created", label: "Date Created" },
  { value: "modified", label: "Date Updated" },
  { value: "upvotes", label: "Most Upvotes" },
  { value: "alphabetical", label: "Alphabetical" }
];

export const SORT_WITH_RELEVANCE: SortOption[] = [
  {
    value: "relevance",
    label: "Relevance"
  },
  ...SORT
];

const FilterLabel = styled.div`
  padding-left: var(--spacing-xlarge);
  padding-right: var(--spacing-default);
  color: ${colors.secondary.ashGrey};
  padding-top: ${pxToRem(8)};
`;

type Props = {
  _tags?: FacetMap;
  sortOptions?: SortOption[];
};
const ContentFilters: React.FC<Props> = ({ _tags, sortOptions = SORT }) => {
  const router = useRouter();
  const sort =
    (toStrippedArray(router.query.sort)[0] as ArticleSort) || "created";
  const tagFilters = toArray(router.query.tags || []);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputValue = e.target.value.trim();
    setInputValue(inputValue);
  };

  const toggleTag = (tag: string, resetInput = false) => {
    let nextTags = tagFilters.slice() || [];
    if (tagFilters.includes(tag)) {
      nextTags = nextTags.filter((t) => t !== tag);
    } else {
      nextTags.push(tag);
    }
    const nextQuery = {
      ...router.query,
      tags: nextTags
    };
    delete nextQuery["page"];
    router.replace(
      {
        pathname: router.pathname,
        query: nextQuery
      },
      undefined,
      { shallow: true }
    );
    if (resetInput) {
      setInputValue("");
    }
  };
  const availableTags: string[] = !!_tags
    ? Object.keys(_tags).filter((tag) => !tagFilters.includes(tag))
    : [];
  const searchTags = inputValue
    ? search(inputValue, availableTags)
    : availableTags;

  return (
    <MediaFiltersElement data-media-filters>
      <FilterLabel>Refine by:</FilterLabel>
      <MediaFilter>
        <Combobox
          onSelect={(tag) => toggleTag(tag, true)}
          openOnFocus
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Tags"
        >
          {ComboxOptions(searchTags)}
        </Combobox>
        <Tags>
          {tagFilters.map((tag) => {
            return (
              <Tag key={tag} onClickClose={() => toggleTag(tag)} close>
                {tag}
              </Tag>
            );
          })}
        </Tags>
      </MediaFilter>
      <FilterLabel>Sort by:</FilterLabel>
      <MediaFilter>
        <Select
          onChange={(e) => {
            const sort = e.target.value;
            router.replace(
              {
                pathname: router.pathname,
                query: {
                  ...router.query,
                  sort
                }
              },
              undefined,
              { shallow: true }
            );
          }}
          value={sort}
        >
          {sortOptions.map(({ value, label }) => (
            <option key={label} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </MediaFilter>
    </MediaFiltersElement>
  );
};

export default ContentFilters;
