import { useEffect, useState } from "react";

type Dimensions = Omit<DOMRectReadOnly, "toJSON">;

export default function useMeasure<T extends HTMLElement>() {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Dimensions>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0
  });

  useEffect(() => {
    if (ref) {
      const observer = new ResizeObserver(([entry]) => {
        setSize(entry.contentRect);
      });

      observer.observe(ref);
      return () => observer.unobserve(ref);
    }

    return () => {};
  }, [ref]);

  return [setRef, size, ref] as const;
}
