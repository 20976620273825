export const DEFAULT_ARTICLE_INDEX = "articles";
export const DEFAULT_ARTICLE_INDICES = {
  relevance: DEFAULT_ARTICLE_INDEX,
  created: "articles_date_created_desc",
  modified: "articles_date_modified_desc",
  upvotes: "articles_upvotes_desc",
  alphabetical: "articles_alphabetical_asc"
} as const;

export type ArticleSort = keyof typeof DEFAULT_ARTICLE_INDICES;
export const ARTICLE_ALLOWED_SORT = new Set<ArticleSort>(
  Object.keys(DEFAULT_ARTICLE_INDICES) as ArticleSort[]
);

export const ensureAllowedSort = (
  input: string | undefined
): ArticleSort | undefined => {
  if (input && ARTICLE_ALLOWED_SORT.has(input as any)) {
    return input as ArticleSort;
  }
};

export function getArticleIndex(sort: ArticleSort | undefined) {
  return sort && sort in DEFAULT_ARTICLE_INDICES
    ? DEFAULT_ARTICLE_INDICES[sort]
    : DEFAULT_ARTICLE_INDEX;
}

type Filter = string;
export function toCategoryFilter(categoryFilters: Filter[]) {
  return `${categoryFilters.map((c) => `_category:"${c}"`).join(" OR ")}`;
}

export function toTagsFilter(tagsFilters: Filter[]) {
  return `${tagsFilters.map((t) => `_tags:"${t}"`).join(" OR ")}`;
}

export function toPhaseFilter(phaseFilters: Filter[]) {
  return `${phaseFilters.map((t) => `_phase:"${t}"`).join(" OR ")}`;
}
export function joinFilters(filters: string[]) {
  const nonEmpty = filters.filter((x) => !!x);
  if (!nonEmpty.length) {
    return "";
  }
  if (nonEmpty.length === 1) {
    return nonEmpty[0];
  }
  return "(" + nonEmpty.join(") AND (") + ")";
}
