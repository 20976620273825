import * as React from "react";
import { Svg } from "./Svg";

function FiltersIcon(props: React.ComponentPropsWithoutRef<typeof Svg>) {
  return (
    <Svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM11.25 6a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6A.75.75 0 014 5.25h8a.75.75 0 010 1.5H4A.75.75 0 013.25 6zM15.25 6a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM8 10.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM5.25 12a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 12a.75.75 0 01.75-.75h2a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75zM9.25 12a.75.75 0 01.75-.75h10a.75.75 0 010 1.5H10a.75.75 0 01-.75-.75zM17 16.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM14.25 18a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 18a.75.75 0 01.75-.75h11a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75zM18.25 18a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default FiltersIcon;
