import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { toArray, toStrippedArray } from "../../api-utils/item-filters";
import { FacetMap } from "../../data/db/types";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { trueOrUndef } from "../../utils/dom-attribute-helpers";
import FiltersIcon from "../icons/Filters";
import { ArticleSort } from "../search/shared";
import {
  activeFiltersCount,
  SORT,
  SortOption,
  stripFilters
} from "./ContentFilters";
import CloseIcon from "../icons/Close";
import { usePortal } from "../../hooks/usePortal";
import Button from "../buttons/Button";
import Accordion from "../accordion/Accordion";
import ListBox from "../list-box/ListBox";
import useLockDocumentScroll from "../../hooks/useLockDocumentScroll";
import { useEscKey } from "../../hooks/useEscKey";
import Heading from "../heading/Heading";
import Checkbox from "../checkbox/Checkbox";
import { fauxUnderlineCss } from "../navigation/fauxUnderlineCss";
import Tag from "../tag/Tag";
import zIndex from "../../tokens/z-index";

const chromelessButtonCss = css`
  appearance: none;
  border: 0;
  padding: 0;
  background: transparent;
  font-family: inherit;
  cursor: pointer;
  touch-action: manipulation;
  color: var(--theme-color-primary);
`;

const MobileContentFiltersElement = styled.div`
  --filters-gutter-major: ${pxToRem(30)};
  --filters-gutter-minor: ${pxToRem(15)};
  padding: var(--filters-gutter-major);
  flex-grow: 1;
  overflow: auto;
  overscroll-behavior: contain;

  [data-accordion] {
    margin-left: calc(var(--filters-gutter-major) * -1);
    margin-right: calc(var(--filters-gutter-major) * -1);
  }
  [data-accordion-title] {
    font-weight: 700;
    color: var(--theme-color-primary);
    padding-right: 0;
    margin-left: var(--filters-gutter-major);
    margin-right: var(--filters-gutter-major);
  }
`;

const RadioOption = styled.label`
  display: flex;
  align-items: center;
  color: var(--theme-color-secondary);
  margin-top: var(--filters-gutter-minor);
  margin-bottom: var(--filters-gutter-minor);
  > input {
    margin-right: var(--filters-gutter-minor);
  }
`;

const MobileContentFiltersControls = styled.div`
  flex-shrink: 1;
  display: flex;
  align-items: center;
  background: var(--theme-color-primary);
`;
const FiltersButton = styled.button`
  ${chromelessButtonCss}

  font-weight: 700;
`;

const MobileSidePanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  width: 100%;
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: ${zIndex.dialog};

  &[aria-hidden] {
    transform: translateX(100%);
  }
`;

const CloseButton = styled.button`
  ${chromelessButtonCss}

  background-color: #FFF;
  position: absolute;
  right: var(--spacing-large);
  top: var(--spacing-large);
`;

const SectionLabel = styled.div`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(22)};
  color: var(--text-color-muted);
  margin-top: var(--filters-gutter-major);
  margin-bottom: var(--filters-gutter-minor);
`;

const footerButtonCss = css`
  flex-grow: 1;
  margin: var(--spacing-small);

  font-weight: 400;

  &[data-size] {
    min-width: auto;
  }
`;

const ClearButton = styled(Button)`
  ${footerButtonCss}

  border: none;
  position: relative;

  ${fauxUnderlineCss}
  &:after {
    margin-top: ${pxToRem(-5)};
  }
`;
const ApplyButton = styled(Button)`
  ${footerButtonCss}

  border-width: ${pxToRem(1)};
  border-radius: ${pxToRem(4)};
`;

export const Tags = styled.div`
  margin-bottom: var(--spacing-xsmall);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: var(--spacing-xsmall);
  margin: calc(var(--spacing-xsmall) * -1);
  > * {
    margin: var(--spacing-xsmall);
  }
`;

type Props = {
  isOpen?: boolean;
  onClick?: () => void;
  onRequestClose: () => void;
  _tags?: FacetMap;
  sortOptions?: SortOption[];
};
const MobileContentFilters: React.FC<Props> = ({
  isOpen = false,
  onRequestClose,
  onClick,
  _tags,
  sortOptions = SORT
}) => {
  const portal = usePortal("mobile-content-filters");
  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isOpen) {
      try {
        // Make the thing accessible
        panelRef.current?.focus();
      } catch {}
    }
  }, [isOpen]);
  const router = useRouter();
  const activeFilters = activeFiltersCount(router.query);
  useLockDocumentScroll(isOpen);
  useEscKey(onRequestClose);
  const sort =
    (toStrippedArray(router.query.sort)[0] as ArticleSort) ||
    sortOptions[0].value;
  const tagFilters = toArray(router.query.tags || []);
  const toggleTag = (tag: string) => {
    let nextTags = tagFilters.slice() || [];
    if (tagFilters.includes(tag)) {
      nextTags = nextTags.filter((t) => t !== tag);
    } else {
      nextTags.push(tag);
    }
    const nextQuery = {
      ...stripFilters(router.query, "page"),
      tags: nextTags
    };
    router.replace(
      {
        pathname: router.pathname,
        query: nextQuery
      },
      undefined,
      { shallow: true }
    );
  };
  return (
    <>
      <FiltersButton onClick={onClick} data-mobile-filters>
        Filters{activeFilters > 0 ? ` (${activeFilters})` : ""} <FiltersIcon />
      </FiltersButton>
      {portal(
        <MobileSidePanel
          ref={panelRef}
          aria-hidden={trueOrUndef(!isOpen)}
          tabIndex={-1}
        >
          <CloseButton onClick={onRequestClose} aria-label="Close Filters">
            <CloseIcon />
          </CloseButton>
          <MobileContentFiltersElement>
            <Heading>Filters</Heading>
            {tagFilters.length > 0 ? (
              <>
                <SectionLabel>Selected filters:</SectionLabel>
                <Tags>
                  {tagFilters.map((tag) => {
                    return (
                      <Tag key={tag} onClickClose={() => toggleTag(tag)} close>
                        {tag}
                      </Tag>
                    );
                  })}
                </Tags>
              </>
            ) : null}
            <SectionLabel>Sort by:</SectionLabel>
            {sortOptions.map(({ value, label }) => {
              return (
                <RadioOption key={value} data-value={value}>
                  <Checkbox
                    type="radio"
                    name="sort"
                    value={value}
                    checked={value === sort}
                    onChange={(e) => {
                      const sort = e.target.value;
                      router.replace(
                        {
                          pathname: router.pathname,
                          query: {
                            ...router.query,
                            sort
                          }
                        },
                        undefined,
                        { shallow: true }
                      );
                    }}
                  />
                  {label}
                </RadioOption>
              );
            })}
            <SectionLabel>Refine by:</SectionLabel>
            {_tags ? (
              <Accordion title="Tags">
                <ListBox
                  items={Object.keys(_tags)}
                  selectedItems={tagFilters}
                  onToggleItem={toggleTag}
                />
              </Accordion>
            ) : null}
          </MobileContentFiltersElement>
          <MobileContentFiltersControls>
            <ClearButton
              appearance="inverted"
              disabled={!activeFilters}
              onClick={() => {
                router.replace(
                  {
                    pathname: router.pathname,
                    query: stripFilters(router.query, "sort", "page", "tags")
                  },
                  undefined,
                  { shallow: true }
                );
                onRequestClose();
              }}
            >
              Clear all
            </ClearButton>
            <ApplyButton appearance="inverted" onClick={onRequestClose}>
              Apply{activeFilters > 0 ? ` (${activeFilters})` : ""}
            </ApplyButton>
          </MobileContentFiltersControls>
        </MobileSidePanel>
      )}
    </>
  );
};

export default MobileContentFilters;
