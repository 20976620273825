import React, { useState } from "react";
import styled from "styled-components";
import { FacetMap } from "../../data/db/types";
import { pxToRem } from "@input-output-hk/px-to-rem";
import HitsCounter from "./HitsCounter";
import ContentFilters, { SORT, SORT_WITH_RELEVANCE } from "./ContentFilters";
import ContentNavigation from "./ContentNavigation";
import SearchNavigation from "./SearchNavigation";
import {
  forTabletLandscapeUp,
  forTabletPortraitDown
} from "../../tokens/media-queries";
import useMediaQuery from "../../hooks/useMediaQuery";
import MobileContentFilters from "./MobileContentFilters";

const CategoryNavigationAndFiltersElement = styled.div`
  @media ${forTabletPortraitDown} {
    display: grid;
    align-items: center;
    grid-template-rows: ${pxToRem(47)} auto auto;
    /* Nav element go at the top.
    We use negative margin to make it
    full-bleed scroll */
    [data-secondary-nav] {
      padding-left: ${pxToRem(12)};
      margin-left: ${pxToRem(-20)};
      margin-right: ${pxToRem(-20)};
    }

    [data-secondary-nav-container] {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    [data-heading-content] {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }

    [data-hits-counter] {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    [data-media-filters] {
      display: none;
    }
  }

  @media ${forTabletLandscapeUp} {
    display: flex;
    flex-direction: column;
    /* Negative margin here to align with parent
    as the first-child has its own padding */
    [data-secondary-nav] {
      margin-left: ${pxToRem(-8)};
    }
  }
`;

const HeadingContentContainer = styled.div`
  display: flex;
  align-items: center;
  [data-mobile-filters] {
    margin-left: auto;
  }
`;

const HitsAndFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${forTabletLandscapeUp} {
    padding-top: var(--spacing-xlarge);
  }
`;

type Props = {
  className?: string;
  headingContent: React.ReactNode;
  categoryNavType?: "search" | "content";
  _tags?: FacetMap;
  hits?: number;
};
const CategoryNavigationAndFilters: React.FC<Props> = ({
  className,
  headingContent,
  _tags,
  hits,
  categoryNavType = "content"
}) => {
  const isTabletPortraitDown = useMediaQuery(forTabletPortraitDown);
  const [filtersOpen, setFiltersOpen] = useState(false);

  return (
    <CategoryNavigationAndFiltersElement className={className}>
      <HeadingContentContainer data-heading-content>
        {headingContent}
        {isTabletPortraitDown && (
          <MobileContentFilters
            isOpen={filtersOpen}
            onClick={() => setFiltersOpen(!filtersOpen)}
            onRequestClose={() => setFiltersOpen(false)}
            _tags={_tags}
          />
        )}
      </HeadingContentContainer>
      {categoryNavType === "search" ? (
        <SearchNavigation />
      ) : (
        <ContentNavigation />
      )}
      <HitsAndFiltersContainer>
        <HitsCounter hits={hits} label={hits !== undefined ? "Results" : ""} />
        <ContentFilters
          _tags={_tags}
          sortOptions={
            categoryNavType === "search" ? SORT_WITH_RELEVANCE : SORT
          }
        />
      </HitsAndFiltersContainer>
    </CategoryNavigationAndFiltersElement>
  );
};

export default CategoryNavigationAndFilters;
