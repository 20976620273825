import * as React from "react";
import { Svg } from "./Svg";

const Tick = (props: React.ComponentPropsWithoutRef<typeof Svg>) => (
  <Svg
    data-icon
    width={15}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.369 9.285 1.424 6.341a.828.828 0 0 0-1.178 0 .828.828 0 0 0 0 1.178l3.525 3.525a.838.838 0 0 0 1.187 0l8.918-8.91a.828.828 0 0 0 0-1.178.828.828 0 0 0-1.178 0l-8.33 8.33Z"
      fill="currentColor"
    />
  </Svg>
);

export default Tick;
