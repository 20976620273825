import { useRouter } from "next/router";
import React from "react";
import { toStrippedArray } from "../../api-utils/item-filters";
import SecondaryNavigation, {
  NavItem
} from "../navigation/SecondaryNavigation";
import { CATEGORY_LABELS } from "../navigation/shared";

type Props = {};
const SearchNavigation: React.FC<Props> = () => {
  const router = useRouter();
  const categories = toStrippedArray(router.query.category);
  return (
    <SecondaryNavigation>
      {Object.entries(CATEGORY_LABELS)
        .filter(([c]) => {
          // Temp disable podcast for now
          return c !== "podcast";
        })
        .map(([c, label]) => {
          const category = c === "content" ? null : c;

          const active =
            (c === "content" && !categories.length) ||
            (category !== null && categories.includes(category));
          return (
            <NavItem
              key={category}
              href={{
                query: {
                  ...router.query,
                  category
                }
              }}
              active={active}
              shallow
            >
              {label}
            </NavItem>
          );
        })}
    </SecondaryNavigation>
  );
};

export default SearchNavigation;
