import { FC, ReactNode, useMemo, useState } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import Tick from "../icons/Tick";

const Element = styled.div``;

const Item = styled.div`
  --border: ${pxToRem(1)} solid var(--default-stroke-color);
  border-top: var(--border);
  color: var(--theme-color-secondary);
  cursor: pointer;

  &:last-child {
    border-bottom: var(--border);
  }

  &[aria-selected="true"] {
    background-color: ${colors.secondary.londonFog};
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  padding: var(--spacing-default) ${pxToRem(50)};
`;

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  [aria-disabled="true"] > & {
    opacity: 0.2;
  }
`;

const IconWrapper = styled.div`
  margin-right: var(--spacing-large);
`;

const ToggleViewAll = styled.button`
  border: 0;
  padding: 0;
  background: 0;
  text-decoration: underline;
  font-family: var(--default-text-font-family);
  font-size: inherit;
  cursor: pointer;
  color: var(--theme-color-secondary);
`;

type Props = {
  items: readonly string[];
  renderItem?: (item: string) => ReactNode;
  selectedItems: readonly string[];
  disabledItems?: readonly string[];
  onToggleItem: (item: string) => void;
  maxShownInitially?: number;
};

const ListBox: FC<Props> = ({
  items,
  onToggleItem,
  renderItem = (item) => item,
  maxShownInitially = -1,
  ...props
}) => {
  const selectedItems = useMemo(
    () => new Set(props.selectedItems),
    [props.selectedItems]
  );

  const disabledItems = useMemo(
    () => new Set(props.disabledItems),
    [props.disabledItems]
  );

  const sliceSize =
    Number.isSafeInteger(maxShownInitially) && maxShownInitially >= 0
      ? Math.min(maxShownInitially, items.length)
      : items.length;

  const hasMore = sliceSize < items.length;

  const [shownFully, setShownFully] = useState(!hasMore);
  const slice = shownFully ? items : items.slice(0, sliceSize);

  return (
    <Element role="listbox">
      {slice.map((item) => (
        <Item
          key={item}
          onClick={() => {
            if (!disabledItems.has(item)) {
              onToggleItem(item);
            }
          }}
          role="option"
          aria-selected={selectedItems.has(item)}
          aria-disabled={disabledItems.has(item)}
        >
          <ItemContent>
            {renderItem(item)}{" "}
            {selectedItems.has(item) ? (
              <IconWrapper>
                <Tick />
              </IconWrapper>
            ) : null}
          </ItemContent>
        </Item>
      ))}
      {hasMore && (
        <Item>
          <ToggleViewAll onClick={() => setShownFully(!shownFully)}>
            {shownFully ? "- View less" : "+ View more"}
          </ToggleViewAll>
        </Item>
      )}
    </Element>
  );
};

export default ListBox;
