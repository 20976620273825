import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forPhoneOnly } from "../../tokens/media-queries";

/**
 * Designed for columns of ContentCards...
 * Desktop = 3 cards
 * Tablet = 2 cards
 * Mobile = 1 card
 */
export const ContentGrid = styled.div`
  --content-grid-card-width: ${pxToRem(370)};
  isolation: isolate;
  display: grid;
  /* Look Mah no media queries! */
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--content-grid-card-width), 1fr)
  );
  gap: ${pxToRem(10)};
  grid-auto-rows: minmax(${pxToRem(100)}, auto);

  @media ${forPhoneOnly} {
    --content-grid-card-width: ${pxToRem(330)};
  }
`;

/**
 * Purpose of this item is to ensure
 * the child `height` is not stretched
 * to match its siblings in the grid
 */
export const ContentGridItem = styled.div`
  max-width: var(--content-grid-card-width);
`;
