import { pxToRem } from "@input-output-hk/px-to-rem";
import React from "react";
import styled from "styled-components";
import colors from "../../tokens/colors";

const HitsCounterElement = styled.div`
  color: ${colors.secondary.ashGrey};
  padding-top: ${pxToRem(8)};
`;

type Props = { hits?: number; label?: string; hideOnMobile?: boolean };
const HitsCounter: React.FC<Props> = ({ hits, label }) => {
  return (
    <HitsCounterElement data-hits-counter>
      {hits} {label}
    </HitsCounterElement>
  );
};

export default HitsCounter;
