import React from "react";
import styled from "styled-components";
import Link from "next/link";
import type { UrlObject } from "url";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { useRouter } from "next/router";
import ChevronRight from "../icons/ChevronRight";
import ChevronLeft from "../icons/ChevronLeft";

type Props = {
  pages: number;
};

export const Pagination: React.FC<Props> = ({ pages }) => {
  const router = useRouter();
  const page = parseInt(String(router.query.page), 10);
  const selectedPage = Number.isSafeInteger(page) ? page : 1;
  const pageSlice = calculatePageSlice(pages, 2, 2, selectedPage);

  const prevHref = React.useMemo<string | UrlObject>(
    () =>
      selectedPage > 1
        ? {
            pathname: router.pathname,
            query: { ...router.query, page: selectedPage - 1 }
          }
        : router.asPath,
    [router, selectedPage]
  );
  const nextHref = React.useMemo<string | UrlObject>(
    () =>
      selectedPage < pages
        ? {
            pathname: router.pathname,
            query: { ...router.query, page: selectedPage + 1 }
          }
        : router.asPath,
    [router, selectedPage, pages]
  );

  return pages > 1 ? (
    <Root>
      <Link href={prevHref} prefetch={false} passHref shallow>
        <IterateButton
          data-testid="btn-pagination-previous"
          aria-hidden={selectedPage === 1}
          aria-label="Go to prev page"
        >
          <ChevronLeft /> Previous
        </IterateButton>
      </Link>

      <Ellipsis visible={pageSlice[0] !== 1} />

      {pageSlice.map((page) => (
        <Link
          passHref
          href={{ pathname: router.pathname, query: { ...router.query, page } }}
          key={String(page)}
          prefetch={false}
          shallow
          scroll
        >
          <PageButton
            data-testid={`link-pagination-page-${page}`}
            aria-current={page === selectedPage}
            aria-label={`Go to page ${page}`}
          >
            {page}
          </PageButton>
        </Link>
      ))}

      <Ellipsis visible={pageSlice[pageSlice.length - 1] !== pages} />

      <Link href={nextHref} prefetch={false} passHref shallow scroll>
        <IterateButton
          data-testid="btn-pagination-next"
          aria-hidden={selectedPage === pages}
          aria-label="Go to next page"
        >
          Next <ChevronRight />
        </IterateButton>
      </Link>
    </Root>
  ) : null;
};

export default Pagination;

const Root = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

const PageButton = styled.a`
  display: block;
  color: var(--pagination-btn-font-color);
  position: relative;
  background-color: transparent;

  width: ${pxToRem(25)};
  height: ${pxToRem(25)};
  line-height: ${pxToRem(25)};

  text-align: center;
  text-decoration: none;

  & + & {
    margin-left: var(--spacing-default);
  }

  &:hover {
    text-decoration: underline;
  }

  &[aria-current="true"] {
    background-color: var(--pagination-selected-btn-background-color);
    pointer-events: none;
    color: var(--pagination-selected-btn-font-color);
  }
`;

const IterateButton = styled.a<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: var(--pagination-btn-font-color);
  margin: 0 ${pxToRem(32)};

  &[aria-hidden="true"] {
    opacity: 0.2;
    color: var(--default-text-color);
    pointer-events: none;
  }

  &:hover {
    text-decoration: underline;
  }

  & > svg:first-child {
    margin-right: ${pxToRem(8)};
  }

  & > svg:last-child {
    margin-left: ${pxToRem(8)};
  }
`;

const Ellipsis = styled.div.attrs<{ visible: boolean }>(({ visible }) => ({
  "data-visible": visible
}))<{ visible: boolean }>`
  opacity: 0;
  visibility: hidden;
  width: 0;
  margin-left: var(--spacing-default);
  transition: all 100ms ease-in;

  &::before {
    content: "\u2026";
  }

  &[data-visible="true"] {
    opacity: 1;
    visibility: visible;
    width: 1em;
  }
`;

function calculatePageSlice(
  total: number,
  howManyBefore: number,
  howManyAfter: number,
  selected: number
): readonly number[] {
  let sliceStart = selected - howManyBefore;
  let sliceEnd = selected + howManyAfter;

  // try to enlarge the slice at the other end
  // e.g. if selected = 1 then sliceStart = 1 - 2 = -1
  // reset sliceStart = 1 and increase sliceEnd by 1 - (-1) = +2
  if (sliceStart < 1) {
    sliceEnd += 1 - sliceStart;
    sliceStart = 1;
  }

  // do the same thing at the other end
  // we might underflow here, e.g. if there are fewer than the required number
  // of pages, we but we have guards setup for this
  if (sliceEnd > total) {
    sliceStart -= sliceEnd - total;
    sliceEnd = total;
  }

  // guard to make sure the ends don't under/overflow :)
  sliceStart = Math.max(sliceStart, 1);
  sliceEnd = Math.min(total, sliceEnd);

  const pages: number[] = [];
  for (let page = sliceStart; page <= sliceEnd; page++) {
    pages.push(page);
  }

  return pages;
}
